<div class="d-flex flex-column spacing-24">
  <h1 class="h1-headers m-0 primary-100">Save filter</h1>

  <div class="d-flex flex-column justify-content-start spacing-16">
    <app-inputs [formControl]="filterNameCtrl" Label="Filter name"></app-inputs>
    <label class="h4-bold primary-100">Filter conditions</label>
    <div class="entries">
      <ng-container *ngFor="let record of records">
        <app-filter-entry-preview
          *ngIf="!record.entry.hidden"
          [record]="record"
          [readonly]="true"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin">
        </app-filter-entry-preview>
      </ng-container>
    </div>
  </div>

  <div class="d-flex flex-column spacing-4">
    <app-buttons
      (btnClick)="handleSave()"
      label="Save"
      [disabled]="!filterNameCtrl.value"></app-buttons>
    <app-secondary-btns (onClick)="handleCancel()" label="Cancel"></app-secondary-btns>
  </div>
</div>
