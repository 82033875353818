<div class="d-flex spacing-4 w-100 align-items-center justify-content-between">
  <div class="d-flex spacing-4 align-items-center">
    <ng-container *ngFor="let record of records">
      <app-filter-entry-preview
        *ngIf="!record.entry?.hidden"
        [record]="record"
        (onRemove)="onRecordRemove($event)"
        (click)="selectRecord(record, trigger)"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin">
      </app-filter-entry-preview>
    </ng-container>
  </div>
  <div class="d-flex spacing-16">
    @if (records && records.length > 0) {
      <app-link-btn
        class="text-nowrap"
        label="Clear all"
        [active]="false"
        size="medium"
        IconType="none"
        [disabled]="false"
        (custom_click)="clearFilter()"></app-link-btn>
    }

    <app-link-btn
      [style.display]="allowCustomFilters && records.length ? 'block' : 'none'"
      (custom_click)="openCreator()"
      class="text-nowrap"
      label="Save as ..."
      [active]="false"
      size="medium"
      IconType="none"
      [disabled]="false"></app-link-btn>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="triggerOrigin!"
  [cdkConnectedOverlayOffsetY]="12"
  [cdkConnectedOverlayOpen]="editorOpen"
  (overlayOutsideClick)="closeEditor()">
  <div class="filter-editor">
    @if (selectedRecord?.entry?.group !== FilterGroupType.Events) {
      <app-filter-entry-editor
        (onApply)="onApply($event)"
        [record]="selectedRecord"></app-filter-entry-editor>
    }
    @if (selectedRecord?.entry?.group === FilterGroupType.Events) {
      <app-filter-entry-events-editor
        (onApply)="onApply($event)"
        [record]="selectedRecord"></app-filter-entry-events-editor>
    }
  </div>
</ng-template>
