import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FilterEntry,
  FilterEventProperty,
  FilterEventSchema,
  FilterGroupType,
  FilterOptionsService,
  FilterRecord,
  getAvailableOperators,
  Operator,
} from '@services/core-services/filters';
import { FilterOperatorSelectComponent } from '@enginuity/core/organisms/filtering/filter-operator-select/filter-operator-select.component';
import { ButtonsComponent } from '@enginuity/core/molecules/buttons/buttons.component';
import { FilterEntryEditorComponent } from '@enginuity/core/organisms/filtering/filter-entry-editor/filter-entry-editor.component';
import { Subscription, tap } from 'rxjs';
import { InputsComponent } from '@enginuity/core/molecules/inputs/inputs.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { base64ToObject } from '@services/core-services/utils';
import { LinkBtnComponent } from '@enginuity/core/molecules/tertiary/link-btn.component';
import { ActionBtnComponent } from '@enginuity/core/molecules/action-btn/action-btn.component';

@Component({
  selector: 'app-filter-entry-events-editor',
  imports: [
    CommonModule,
    FilterOperatorSelectComponent,
    ButtonsComponent,
    FilterEntryEditorComponent,
    InputsComponent,
    ReactiveFormsModule,
    LinkBtnComponent,
    ActionBtnComponent,
  ],
  templateUrl: './filter-entry-events-editor.component.html',
  styleUrl: './filter-entry-events-editor.component.scss',
})
export class FilterEntryEventsEditorComponent implements OnDestroy {
  private _record: FilterRecord | undefined;
  private subscriptions: Subscription[] = [];
  private value: any;

  protected loading: boolean = false;
  protected internalRecord: FilterRecord | undefined;
  protected nestedRecord: FilterRecord | undefined;
  protected properties: { label: string; value: string }[] = [];
  protected schema: FilterEventSchema | undefined;
  protected withProperty: boolean = false;
  protected propertyCtrl = new FormControl('');

  get record(): FilterRecord | undefined {
    return this._record;
  }

  @Input() set record(record: FilterRecord | undefined) {
    if (record) {
      const { rule, entry } = record;
      const operator: Operator =
        rule?.operator || getAvailableOperators(record)?.[0] || Operator.Equals;
      this._record = { entry, rule: { ...rule, operator } };
    } else {
      this._record = record;
    }

    if (record?.entry.id) {
      this.loadPropertyOptions(record.entry.id);
    }

    if (record) {
      this.value = this.getRuleValue(record);
    }

    this.internalRecord = JSON.parse(JSON.stringify(this._record || {}));
  }

  @Output() onApply: EventEmitter<FilterRecord> = new EventEmitter<FilterRecord>();

  constructor(private readonly filterOptionsService: FilterOptionsService) {}

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onOperatorChange(operator: any) {
    const record = this._record!;
    const rule = record.rule;
    this._record = { ...record, rule: { ...rule, operator } };
    this.internalRecord = JSON.parse(JSON.stringify(this._record || {}));
  }

  onPropertyChange({ value }: any) {
    const property = this.schema?.properties.find(p => p.name === value);
    if (!property) return;
    this.withProperty = true;
    const param = this.getRuleValue(this.record!);
    this.nestedRecord = this.createNestedRecord(property, param);
    this.propertyCtrl.setValue(value);
  }

  handleApply(record?: FilterRecord) {
    this._record = {
      ...this.record,
      rule: {
        ...this.record!.rule,
        value: record?.rule || undefined,
      },
    } as FilterRecord;
    this.onApply.emit(this.record);
  }

  refine(flag: boolean) {
    this.withProperty = flag;

    if (!flag) {
      this._record = {
        ...this.record,
        rule: {
          ...this.record!.rule,
          value: undefined,
        },
      } as FilterRecord;
      this.onApply.emit(this.record);
    }
  }

  private getRuleValue(record: FilterRecord) {
    const { rule } = record;
    if (!rule.value) return undefined;
    return base64ToObject(rule.value);
  }

  private loadPropertyOptions(id: string) {
    this.loading = true;
    this.subscriptions.push(
      this.filterOptionsService
        .getEventFilterPropertyDetails(id)
        .pipe(
          tap(() => (this.loading = false)),
          tap(schema => this.handleSchemaLoad(schema))
        )
        .subscribe()
    );
  }

  private handleSchemaLoad(schema: FilterEventSchema) {
    this.schema = schema;
    if (!schema) return;

    this.properties = schema.properties.map(p => ({ label: p.name, value: p.name }));
    if (this.value?.field) {
      this.onPropertyChange({ value: this.value?.field });
    }
  }

  private createNestedRecord(property: FilterEventProperty, entry?: FilterEntry) {
    return {
      entry: {
        field: property.name,
        group: FilterGroupType.Data,
        label: property.name,
        type: property.data_type,
        source: (property.properties || []).map(p => ({ value: p.name, key: p.name })),
      },
      rule: {
        field: property.name,
        type: property.data_type,
        operator: entry?.operator,
        value: entry?.value || '',
      },
    };
  }
}
