import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FilterRecord,
  getAvailableOperators,
  getReadableName,
  Operator,
} from '@services/core-services/filters';
import { InputsComponent } from '@enginuity/core/molecules/inputs/inputs.component';
import { InputsValue } from '@services/ui-services/models';

type Option = { value: string; label: string };

@Component({
  standalone: true,
  selector: 'app-filter-operator-select',
  imports: [CommonModule, InputsComponent],
  templateUrl: './filter-operator-select.component.html',
  styleUrl: './filter-operator-select.component.scss',
})
export class FilterOperatorSelectComponent {
  private _record!: FilterRecord;

  get record(): FilterRecord {
    return this._record;
  }

  @Input() set record(record: FilterRecord) {
    this._record = record;
    const operator = record.rule.operator;
    this.options = getAvailableOperators(record).map(x => ({
      value: x,
      label: getReadableName(x as Operator),
    }));
    this.selection = operator ?? ((this.options[0]?.value || '') as InputsValue);
  }

  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();

  public selection: InputsValue = '';
  public options: Option[] = [];

  onDropdownChange(option: Option) {
    this.selection = option.value;
    this.onChange.emit(option.value);
  }
}
