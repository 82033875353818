import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterRecord } from '@services/core-services/filters/fitler-record';
import { FilterEntryPreviewComponent } from '@enginuity/core/organisms/filtering/filter-entry-preview/filter-entry-preview.component';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { FilterEntryEditorComponent } from '@enginuity/core/organisms/filtering/filter-entry-editor/filter-entry-editor.component';
import { LinkBtnComponent } from '@enginuity/core/molecules/tertiary/link-btn.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FilterContext, FilterGroupType } from '@services/core-services/filters';
import { ModalService } from '@services/core-services/modal.service';
import { FilterSaveFormComponent } from '@enginuity/core/organisms/filtering/filter-save-form/filter-save-form.component';
import { NotifyService } from '@services/core-services/notify.service';
import { FilterEntryEventsEditorComponent } from '@enginuity/core/organisms/filtering/filter-entry-events-editor/filter-entry-events-editor.component';

@Component({
  selector: 'app-filter-preview',
  templateUrl: './filter-preview.component.html',
  styleUrl: './filter-preview.component.scss',
  imports: [
    CommonModule,
    FilterEntryPreviewComponent,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    FilterEntryEditorComponent,
    LinkBtnComponent,
    ReactiveFormsModule,
    FilterEntryEventsEditorComponent,
  ],
})
export class FilterPreviewComponent implements OnChanges {
  protected readonly FilterGroupType = FilterGroupType;

  @Input() records: FilterRecord[] = [];
  @Input() editorOpen: boolean = false;
  @Input() allowCustomFilters: boolean = false;

  @Output() onRemove: EventEmitter<FilterRecord> = new EventEmitter<FilterRecord>();
  @Output() clearAllFilter: EventEmitter<FilterRecord> = new EventEmitter<FilterRecord>();
  @Output() onSaveFilter: EventEmitter<FilterContext> = new EventEmitter<FilterContext>();
  @Output() onChange: EventEmitter<FilterRecord[]> = new EventEmitter<FilterRecord[]>();

  public selectedRecord: FilterRecord | undefined;
  public triggerOrigin: CdkOverlayOrigin | undefined;

  constructor(
    private readonly modalService: ModalService,
    private readonly notify: NotifyService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const records = (changes['records']?.currentValue || []) as FilterRecord[];

    if (changes['records']) {
      this.selectedRecord = records.find(record => record.active);
    }
  }

  onRecordRemove(record: FilterRecord) {
    this.onRemove.emit(record);
  }

  clearFilter() {
    this.clearAllFilter.emit();
  }

  selectRecord(record: FilterRecord, trigger: CdkOverlayOrigin) {
    this.selectedRecord = record;
    this.triggerOrigin = trigger;
    this.editorOpen = true;
  }

  closeEditor() {
    this.selectedRecord = undefined;
    this.triggerOrigin = undefined;
    this.editorOpen = false;
  }

  openCreator() {
    this.modalService
      .open(FilterSaveFormComponent, {
        data: {
          records: this.records,
        },
      })
      .subscribe(result => {
        if (result) {
          this.onSaveFilter.emit({
            rules: result.records.map((x: any) => x.rule),
            name: result.name,
          });

          this.notify.success('Filter saved successfully');
        }
      });
  }

  onApply(record: FilterRecord) {
    this.updateRecords(record);
    this.onChange.emit(this.records);
    this.closeEditor();
  }

  private updateRecords(record: FilterRecord) {
    this.records = this.records.map(r =>
      r.entry.field === record?.entry.field ? { ...record } : r
    );
  }
}
