import { Component, Input } from '@angular/core';
import { FilterRecord } from '@services/core-services/filters';
import { NgForOf, NgIf } from '@angular/common';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { FilterEntryPreviewComponent } from '@enginuity/core/organisms/filtering/filter-entry-preview/filter-entry-preview.component';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputsComponent } from '@enginuity/core/molecules/inputs/inputs.component';
import { ButtonsComponent } from '@enginuity/core/molecules/buttons/buttons.component';
import { SecondaryBtnsComponent } from '@enginuity/core/molecules/secondary-btns/secondary-btns.component';
import { ModalService } from '@services/core-services/modal.service';

@Component({
  selector: 'app-filter-save-form',
  imports: [
    CdkOverlayOrigin,
    FilterEntryPreviewComponent,
    NgForOf,
    NgIf,
    InputsComponent,
    ReactiveFormsModule,
    ButtonsComponent,
    SecondaryBtnsComponent,
  ],
  templateUrl: './filter-save-form.component.html',
  styleUrl: './filter-save-form.component.scss',
})
export class FilterSaveFormComponent {
  @Input() records: FilterRecord[] | undefined;

  public filterNameCtrl = new FormControl('', [Validators.required]);

  constructor(private readonly modalService: ModalService) {}

  handleSave() {
    this.modalService.close({
      name: this.filterNameCtrl!.value,
      records: this.records,
    });
  }

  handleCancel() {
    this.modalService.close();
  }
}
