import {
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPreview, FilterRecord, Operator } from '@services/core-services/filters';
import { CdkPortalOutletAttachedRef, ComponentPortal, PortalModule } from '@angular/cdk/portal';
import { FilterService } from '@services/core-services/filters/filter.service';
import { ComponentType } from '@angular/cdk/overlay';

@Component({
  selector: 'app-filter-entry-preview',
  imports: [CommonModule, PortalModule],
  templateUrl: './filter-entry-preview.component.html',
  styleUrl: './filter-entry-preview.component.scss',
})
export class FilterEntryPreviewComponent implements OnChanges {
  @Input() record!: FilterRecord;
  @Input() readonly: boolean = false;

  @Output() onRemove: EventEmitter<FilterRecord> = new EventEmitter<FilterRecord>();

  public previewRef: ComponentRef<FilterPreview> | undefined;
  public previewPortal: ComponentPortal<FilterPreview> | undefined;

  constructor(private readonly filterService: FilterService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const record = (changes['record']?.currentValue || this.record) as FilterRecord;

    const Component = this.filterService.resolvePreviewComponent(
      record.entry
    ) as ComponentType<FilterPreview>;

    if (Component) {
      this.previewPortal = new ComponentPortal(Component);
    }
  }

  onFormComponentRender(ref: CdkPortalOutletAttachedRef) {
    this.previewRef = ref as ComponentRef<FilterPreview>;
    this.previewRef.instance.record = this.record;
    this.previewRef.instance.readonly = this.readonly;
    this.previewRef.instance.onRemove.subscribe((record: FilterRecord) =>
      this.onRemove.emit(record)
    );
  }
}
