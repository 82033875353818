<ng-container *ngIf="record">
  <div class="d-flex flex-column spacing-16">
    <div class="d-flex align-items-center spacing-4">
      <span>{{ record.entry.label }}</span>

      <app-filter-operator-select
        [record]="record"
        (onChange)="onOperatorChange($event)"></app-filter-operator-select>

      @if (!loading && !schema) {
        <app-buttons
          [shortcutKey]="false"
          IconType=""
          label="Apply"
          (btnClick)="handleApply(undefined)"></app-buttons>
      }
    </div>

    <!-- Nested -->

    @if (loading) {
      <span>Loading ...</span>
    }

    @if (!loading && !withProperty && schema) {
      <div class="pb-16">
        <app-link-btn label="Refine" IconType="add" (custom_click)="refine(true)"></app-link-btn>
      </div>
    }

    @if (!loading && withProperty) {
      <div class="d-flex align-items-center spacing-4">
        <app-action-btn
          IconType="cancel"
          (custom_click)="refine(false)"
          [active]="false"></app-action-btn>

        <span>with</span>

        <app-inputs
          [dropdown]="true"
          Label=""
          [formControl]="propertyCtrl"
          [options]="properties"
          (onChange)="onPropertyChange($event)"></app-inputs>

        @if (nestedRecord) {
          <app-filter-entry-editor
            [showName]="false"
            [record]="nestedRecord"
            (onApply)="handleApply($event)"></app-filter-entry-editor>
        }
      </div>
    }
  </div>
</ng-container>
